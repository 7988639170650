/**
 * Site header
 */
.site-header {
  //Positioning context for the mobile navigation icon
  position: relative;
  background-color: $navbar-bg-color;
  color: $navbar-text-color;
  a {
    color: $navbar-text-color;

    &:hover {
      color: $navbar-text-color-accent;
    }
  }
}

.navbar-menu {
  margin: $spacing-unit 0;
  li {
    display: inline;
    list-style-type: none;
    &:not(:first-child) {
      padding-left: 10px;
    }
  }
}


/**
 * Sidebar
 */
ul.list-unstyled {
  margin: 0;
  li {
    list-style: none;
  }
}

/**
 * Posts
 */

#markdown-toc {
  float: right;
  width:50%;
  background:#f4f7f8;
  padding:20px 15px;
  li {
    padding-top:10px;
    position:relative;
    list-style-type:none;
  }
}

#markdown-toc:before {
  content:"Índice";
  font-weight: 700;
  padding-left:10px;
  border-bottom:5px solid $grey-color-light !important;
  display:block;
  font-size:18px
}

/**
 * Pagination Style
 */

.pager {
  margin-bottom: 60px;
  text-align: center;
  ul { display: block; margin: 20px 0; }
  li { list-style: none; }
  li.previous a{ float: left; }
  li.next a { float: right; }
  li.disabled { display: none; }
}

/*time style of tages and categories*/

time {
  font-style: italic;
}


/**
 * New style for quotation.
 */

q:before {
  content: '『';
  color: #DD1144;
  font-weight:bold;
}
q:after {
  content:'』';
  color: #DD1144;
  font-weight:bold;
}














/***************************************************/
body {
  font-family: $base-font-family;
}
h1, h2, h3, h4, h5 {
  font-family: $base-font-family;
  font-weight: $bold-weight;
  color: $title-text-color;
}


.site-title {
  font-size: 20px;
  font-family: $base-font-family;
  margin: $spacing-unit 0;
  text-align:center;
  a {
    &,
    &:visited
    &:hover {
      text-decoration: none;
    }
  }
}

.site-nav {
  display: block;
  overflow: hidden;
  padding: 0 $spacing-unit;
  &:before {
    content: "";
  }
  .site-nav-left {
    float: left;
  }
  .site-nav-right {
    float: right;
  }
  .menu-icon {
    display: none;
  }

  .page-link {
    color: $navbar-text-color;
    line-height: $base-line-height;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
      //font-weight: bold;
    }
  }


  @include media-query($on-palm) {
    position: relative;
    padding: 0 20px;
    text-align: right;
    .menu-icon {

      display: block;
      padding-top: $spacing-unit;

      > svg {
        width: 18px;
        height: 30px;

        path {
          fill: $navbar-text-color;
        }
      }
    }

    .trigger {
      display: none;
    }

    .menu-icon:hover + .trigger,
    .menu-icon:focus + .trigger,
    .trigger:hover {
      padding-bottom: 5px;
      display: block;
    }

    .page-link {
      text-align: right;
      display: block;
    }
  }

}

.card {
  background: $card-bg-color;
  margin-bottom: $spacing-unit-lg * 2;
  padding-bottom: 20px;
  .featured-image {
    border-radius: $border-radius $border-radius 0 0;
    width: 100%;
  }
  .card__title {
    font-size: 38px;//42px;
    line-height: 1;
    margin-bottom: $spacing-unit-lg;

    @include media-query($on-laptop) {
      font-size: 36px;
    }
    a {
      -webkit-transition: $default-transition;
         -moz-transition: $default-transition;
           -o-transition: $default-transition;
              transition: $default-transition;
      &,
      &:visited {
        color: $text-color;
      }

      &:hover {
        color: $brand-color;
      }
    }
  }
  .card__content {
    padding: $spacing-unit-lg;
    .card__content__meta {
      font-size: $small-font-size;
      color: $grey-color;
    }
    .card__content__text {
      table {
        thead {
          &,
          tr,
          th {
            background-color: $grey-color-light;
          }
        }
        width: 100%;
        max-width: 100%;
        margin-bottom: $spacing-unit;

        th,
        td {
          padding: $spacing-unit-sm;
          vertical-align: top;
          border-top: 1px solid $grey-color;
        }

        thead th {
          vertical-align: bottom;
          border-bottom: (2 * 1px) solid $grey-color-light;
        }

        tbody + tbody {
          border-top: (2 * 1px) solid $grey-color-light;
        }
      }
      figure {
        text-align: center;
        margin: $spacing-unit-lg 0;
        img {
          margin: 0 auto;
          border-radius: $border-radius;
        }
      }
      margin-top: $spacing-unit-lg;
      p {
        margin-bottom: $spacing-unit;
      }
      h1, h2, h3, h4, h5, h6 {
        margin-top: $spacing-unit-lg * 2;
        margin-bottom: $spacing-unit-lg;
      }
    }
  }
  .card__actions {
    margin-top: $spacing-unit-lg;
    text-align: right;
  }

  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
.card--hover {
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: $box-shadow-hover;
  }
}

.hidden-palm {
  @include media-query($on-palm) {
    display: none;
  }
}
.btn {
  -webkit-transition: $default-transition;
     -moz-transition: $default-transition;
       -o-transition: $default-transition;
          transition: $default-transition;
  &,
  &:visited,
  &:active {
    padding: $spacing-unit-sm;
    border-radius: $border-radius;
  }
  &:hover {
    text-decoration: none;
  }
}
.btn + .btn {
  margin-left: $spacing-unit-sm;
}
.btn-primary {
  &,
  &:visited,
  &:active {
    background-color: $brand-color;
    color: $btn-text-color;
  }
  &:hover {
    color: $btn-text-color;
    background-color: $hover-color;
  }
}
.btn-secondary {
  &,
  &:visited,
  &:active {
    color: $text-color;
    background-color: $grey-color-light;
  }
  &:hover {
    background-color: $brand-secondary-color;
    color: $btn-text-color;
  }
}
.btn-default {
  &,
  &:visited,
  &:active {
    background-color: $grey-color-light;
    color: $text-color;
  }
  &:hover {
    color: $btn-text-color;
    background-color: $brand-color;
  }
}

.btn-facebook {
  &,
  &:visited,
  &:active {
    background-color: $facebook-color;
    color: $btn-text-color;
  }
  &:hover {
    color: $btn-text-color;
    background-color: $facebook-color-hover;
  }
}
.btn-twitter {
  &,
  &:visited,
  &:active {
    background-color: $twitter-color;
    color: $btn-text-color;
  }
  &:hover {
    color: $btn-text-color;
    background-color: $twitter-color-hover;
  }
}
.disabled {
   pointer-events: none;
   &:hover {
    cursor: not-allowed;
   }
}

.sidebar {
  margin: $spacing-unit-lg 0;
  padding-top: $spacing-unit;
  a {
    &,
    &:visited {
      color: $sidebar-text-color;
    }
  }
}
.sidebar-module {
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;
  &:first-child,
  &:last-child {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid $grey-color-light;
  }
}
/**
 * Site footer
 */

footer {
  margin-top: $spacing-unit-lg;
  clear: both;
  background-color: $navbar-bg-color;
  color: $navbar-text-color;
  padding: $spacing-unit-lg;
  text-align: center;
}

.section-header {
  margin: $spacing-unit-lg 0;
  h1 {
    font-weight: $bold-weight;
    color: $brand-color;
  }
}

.list-horizontal {
  li {
    &:not(:first-child) {
      margin-left: $spacing-unit-lg;
    }
    display: inline-block;
    list-style-type: none;
  }
}

.page-content {
  margin: $spacing-unit-lg 0;
}

.breadcrumbs {
  i {
    margin: 0 $spacing-unit-sm;
  }
  margin-bottom: $spacing-unit-lg;
}

.share {
  text-align: center;
  margin-top: $spacing-unit-lg;
  a:not(:first-child) {
    margin-left: $spacing-unit;
  }
}
.comments {
  padding-top: $spacing-unit-lg;
}

.center {
  margin: auto;
  display: block;
}

#mc_embed_signup {
  margin-top: 20px;
}

.amazon-disclaimer {
  font-size: $small-font-size;
  text-align: left;
}

.post-navigator {
  padding: 0;
  margin: 0;
  li.card {
    a {
      & {
        text-decoration: none;
        color: $text-color;
      }
    }
    display: block;
    list-style: none;
    width: 45%;
    @include media-query($on-palm) {
      float: none;
      width: 100%;
    }
  }
  li.card.previous {
    float: left;
  }
  li.card.next {
    float: right;
  }
}


.card__text--lg {
  font-size: 18px;
  margin: 0;
}
.pull-right {
  float: right;
}
.text-right {
  text-align: right;
}
.banner-header {
  text-align: center;
  margin-top: 10px;
}
.navbar-brand {
  max-height: 24px;
  vertical-align: text-bottom;
  cursor: pointer;
}
.follow-container {
  display: flex;
  flex-direction: row
}

.follow-facebook,
.follow-twitter {
  &:not(:first-child) {
    margin-left: 4px; // Magic number
  }
  align-self: auto;
}
.follow-twitter {
  margin: 5px;  // Magic number
}
