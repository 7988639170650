@charset "utf-8";

// Our variables
$base-font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.6em;

$spacing-unit-sm:  10px;
$spacing-unit:     15px;
$spacing-unit-lg:  20px;

$border-radius-sm: 2px;
$border-radius: 4px;

$card-bg-color: #fff;
$bold-weight: 700;

$box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$box-shadow-hover: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

$title-text-color:         #444444;
$text-color:               #6B6B6B;
$btn-text-color:           white;
$background-color:         #f9f9f9;
$brand-color:              #469AE9;
$brand-secondary-color:    #363636;
$hover-color:              #363636;
$navbar-bg-color:          $brand-secondary-color;
$navbar-text-color:        #DADADA;
$navbar-text-color-accent: #EAEAEA;

$facebook-color: #2E428B;
$facebook-color-hover: lighten($facebook-color, 10%);

$twitter-color: #268AF8;
$twitter-color-hover: lighten($twitter-color, 10%);

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);
$sidebar-text-color: lighten($grey-color, 25%);

$code-backgroud-color: #29281e;
$code-font-color:  #eee;

$default-transition: background-color 0.3s linear;

// Width of the content area
$content-width:    960px;
$page-width:       1200px;

$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
"base",
"layout"
;
